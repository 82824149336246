import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { CaseStudyCollection } from "../../components/CaseStudy/CaseStudyCollection"
import { graphql } from "gatsby"
import { CallMeBackFull } from "../../components/Misc/CallMeBackFull"

const CaseStudies = ({ data }) => {
  const {
    allContentfulCaseStudy: { nodes: caseStudies },
  } = data

  // console.log(caseStudies)
  return (
    <Layout>
      <SEO
        title="Case Studies"
        description="The reason investors choose to work with us is our consultative approach which is tailored to meet your lifestyle and financial goals, by making suggestions that match the outcomes you seek. Read about UK property investment case studies."
      />
      <Container>
        <Row>
          <Col>
            <h1>Case Studies</h1>
            <p className="lead">
              How challenging can it be to find someone who is dependable,
              listens and respond with suggestions that can help you achieve
              your medium-term objectives?
            </p>
            <p>
              Here you will find case studies of customers who shared their
              current dilemmas and their feelings about what they really wanted
              to achieve. Not the cash amount they wanted to generate but rather
              the meaningful life experiences they wanted to enjoy with that
              extra money. A percentage or an extra zero on the end of a return
              is boring, it’s what you plan to do with it that makes life
              exciting and meaningful.
            </p>
          </Col>
        </Row>
      </Container>

      <CaseStudyCollection items={caseStudies} />

      <CallMeBackFull />
    </Layout>
  )
}

export const query = graphql`
  query CaseStudiesPageQuery {
    allContentfulCaseStudy {
      nodes {
        id
        title
        who {
          childMarkdownRemark {
            html
          }
        }
        what {
          childMarkdownRemark {
            html
          }
        }
        when {
          childMarkdownRemark {
            html
          }
        }
        image {
          fluid(maxHeight: 500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        why {
          childMarkdownRemark {
            html
          }
        }
        how {
          childMarkdownRemark {
            html
          }
        }
        results {
          childMarkdownRemark {
            html
          }
        }
        sector {
          key
        }
      }
    }
  }
`

export default CaseStudies
