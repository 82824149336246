import React from "react"
import Img from "gatsby-image"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { AccordionBasic } from "../Accordion/AccordionBasic"

import { CaseStudySector } from "./CaseStudySector"

const StyledCaseStudy = styled.div`
  &.bg-primary {
    a {
      color: ${({ theme }) => theme.light};
    }
  }

  &.bg-light {
    .sector-wrapper.logo {
      svg {
        background-color: ${({ theme }) => theme.primary};
      }
    }
  }
`

const StyledImage = styled(Img)`
  border: 5px solid white;
`

export const CaseStudy = ({ item, variant = "light" }) => {
  return (
    <StyledCaseStudy
      className={`bg-${variant} pt-5 ${
        variant !== "light" ? "text-light" : "text-primary"
      }`}
    >
      <Container>
        <Row>
          <Col>
            <h2 className="mb-5">{item.title}</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6} xl={3}>
            <h3>Who</h3>
            {item.who && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.who.childMarkdownRemark.html,
                }}
              />
            )}
          </Col>
          <Col md={6} xl={3}>
            <h3>What</h3>
            {item.what && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.what.childMarkdownRemark.html,
                }}
              />
            )}
          </Col>
          <Col md={6} xl={3}>
            <h3>When</h3>
            {item.when && (
              <div
                dangerouslySetInnerHTML={{
                  __html: item.when.childMarkdownRemark.html,
                }}
              />
            )}
          </Col>
          <Col md={6} xl={3}>
            <StyledImage
              fluid={item.image.fluid}
              className={`border-${
                (item.sector && item.sector.key) || "white"
              }`}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <AccordionBasic>
              <Row className="mt-4">
                <Col md={6} xl={3}>
                  <h3>Why</h3>
                  {item.why && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.why.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </Col>
                <Col md={6} xl={3}>
                  <h3>How</h3>
                  {item.how && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.how.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </Col>
                <Col md={6} xl={3}>
                  <h3>Results</h3>
                  {item.results && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.results.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </Col>
                <Col md={6} xl={3}>
                  <CaseStudySector
                    sectorKey={(item.sector && item.sector.key) || "logo"}
                  />
                </Col>
              </Row>
            </AccordionBasic>
          </Col>
        </Row>
      </Container>
    </StyledCaseStudy>
  )
}
