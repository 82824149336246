import React from "react"
import { Icon } from "../IconFactory/Icons"
import styled from "styled-components"
import { Link } from "gatsby"

const SectorWrapper = styled(Link)`
  svg {
    width: 100%;
    height: auto;
    /* Custom */
    max-width: 150px;
    display: block;
    padding: 15px;
    margin: auto;
  }
`

export const CaseStudySector = ({ sectorKey = "logo" }) => {
  const Text = () => {
    switch (sectorKey) {
      //    Sectors
      case "buy-to-let":
        return <p>Find out how Buy-to-let Investment guides could help you</p>
      case "care-home":
        return <p>Find out how Care Home Investment guides could help you</p>
      case "hotel-room":
        return <p>Find out how Hotel Room Investment guides could help you</p>
      case "student-property":
        return (
          <p>Find out how Student Property Investment guides could help you</p>
        )
      case "logo":
        return (
          <p>One Touch can help you develop a property investment strategy </p>
        )

      default:
        return null
    }
  }
  return (
    <SectorWrapper
      to="/uk-property-investment-guides-videos"
      className={`sector-wrapper ${sectorKey}`}
    >
      <Icon name={sectorKey} />
      <Text />
      <p>{`>>>`}</p>
    </SectorWrapper>
  )
}
