import React from "react"
import { CurveCarveTop } from "../Curves/Curve"
import { CaseStudy } from "./CaseStudy"

export const CaseStudyCollection = ({ items = [] }) => {
  if (items.length < 1) return null

  return (
    <div>
      <CurveCarveTop variant="light" />

      {items.map((item, i) => (
        <React.Fragment key={item.id}>
          <CaseStudy item={item} variant={i % 2 === 0 ? "light" : "primary"} />
          <CurveCarveTop
            variant={
              i + 1 === items.length
                ? "white"
                : i % 2 === 0
                ? "primary"
                : "light"
            }
            background={i % 2 === 0 ? "light" : "primary"}
          />
        </React.Fragment>
      ))}
    </div>
  )
}
