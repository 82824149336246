import React, { useState } from "react"
import { Accordion, useAccordionToggle } from "react-bootstrap"
import styled, { keyframes } from "styled-components"

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(25%);
  }
  40% {
    transform: translateY(-25%);
  }
  60% {
    transform: translateY(-15%);
  }
`

const StyledAccordionToggle = styled.button`
  /* Normalize Button */
  width: 100%;
  border: 0;
  border-radius: 0;
  outline: 0 !important;
  background: none;
  /* Style */
  padding: 15px 0;

  /* Icon */
  svg.triangle {
    display: block;
    margin: auto;
    width: 25px;
    height: 25px;
    transition: all 0.25s ease-in-out;
    fill: ${({ theme }) => theme.secondary};
  }

  &:hover {
    svg.triangle {
      fill: ${({ theme }) => theme.primary};
    }

    .bg-primary & {
      svg.triangle {
        fill: ${({ theme }) => theme.light};
      }
    }
  }

  &.open {
    svg.triangle {
      transform: rotate(180deg);
    }
  }

  &:not(.open) {
    svg.triangle {
      animation: ${bounce} 2s linear infinite;
    }
  }
`

function CustomToggle({ eventKey, defaultOpen }) {
  const [open, setOpen] = useState(defaultOpen)

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpen(!open)
  })

  return (
    <StyledAccordionToggle
      className={open ? "open" : null}
      onClick={decoratedOnClick}
      aria-label="Toggle Accordion"
    >
      <svg className="triangle" viewBox="0 0 31 29">
        <path d="M15.5,29L0,0h31L15.5,29z" />
      </svg>
    </StyledAccordionToggle>
  )
}

export const AccordionBasic = ({ defaultOpen = false, children }) => {
  return (
    <Accordion defaultActiveKey={defaultOpen ? "0" : null}>
      <CustomToggle defaultOpen={defaultOpen} eventKey="0" />

      <Accordion.Collapse eventKey="0">
        <>{children}</>
      </Accordion.Collapse>
    </Accordion>
  )
}
